/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} icon - icon that was clicked to add a product to the wishlist
 */
function displayMessageAndChangeIcon(data, icon, index) {
	let status;
	let eventType;

	if (data.success) {
		const miniHeart = $('.miniheart-quantity');
		const currentQuantity = parseInt($('.miniheart-quantity').html());
		status = 'alert-success';
		if (icon.hasClass('fa-heart-o')) { // add
			icon.removeClass('fa-heart-o').addClass('fa-heart');
			miniHeart.empty().append(currentQuantity + 1);
			icon.parents(".wishlistTile").attr('href', $("#removeWishlistURL").val());
			addWishlistData(data.gtmData, index);
		}else if(icon.hasClass('fa-heart')){ // remove
			icon.removeClass('fa-heart').addClass('fa-heart-o');
			miniHeart.empty();
			if(currentQuantity >= 1){
				miniHeart.append(currentQuantity - 1);
			}
			icon.parents(".wishlistTile").attr('href', $("#addWishlistURL").val());
			eventType = 'removeFromWishlist';

			const name =
				data.gtmData && data.gtmData.productName && data.gtmData.shortDescription
					? `${data.gtmData.productName} - ${data.gtmData.shortDescription}`
					: icon.parents('.wishlistTile').data('product-name');
			const id = data.gtmData && data.gtmData.id ? data.gtmData.id : icon.parents('.wishlistTile').data('product-id');
			const image = icon.parents('.wishlistTile').data('image');

			$('body').bootstrap('gtm', {
				type: eventType,
				data: {
					id,
					name,
					image,
				},
			});
		}

	} else {
		status = 'alert-danger';
	}

	if ($('.add-to-wishlist-messages').length === 0) {
		$('body').append('<div class="add-to-wishlist-messages"></div>');
	}
	$('.add-to-wishlist-messages').append(`<div class="add-to-wishlist-alert text-center ${status}">${data.msg}</div>`);

	setTimeout(function() {
		$('.add-to-wishlist-messages').remove();
	}, 5000);
}

function addWishlistData(product, index) {
	const productId = product.id;
	const name = product.productName;
	const brand = product.brand;
	const variant = product.finish;
	const price = product.price.list ? product.price.list.value : product.price.sales.value;
	const currency = product.price.sales.currency;
	const discount = product.price.list ? product.price.list.value - product.price.sales.value : 0;
	var promotions = product.promotions;
	var PromotionList = [];
	if (promotions) {
		for (let i = 0; i < promotions.length; i++) {
			var promo = promotions[i];
			if (promo && promo.enabled) {
				PromotionList.push({ id: promo.id, name: promo.name });
			}
		}
	}
	var category = product.categories;
	const categoryData = {
		category1: category && category.length > 0 && category[0] ? category[0].name : "",
		category2: category && category.length > 0 && category[1] ? category[1].name : "",
		category3: category && category.length > 0 && category[2] ? category[2].name : "",
		category4: category && category.length > 0 && category[3] ? category[3].name : "",
		category5: category && category.length > 0 && category[4] ? category[4].name : "",
		list_id: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].id : "",
		list_name: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].name : "",
	}
	var data = {
		event: "add_to_wishlist",
		ecommerce: {
			currency: currency,
			value: product.price.sales ? product.price.sales.value : price,
			items: [
				{
					item_id: productId,
					item_name: name,
					affiliation: "Amart Online",
					coupon: PromotionList,
					currency: currency,
					discount: discount.toFixed(2),
					index: index,
					item_brand: brand,
					item_category: categoryData.category1,
					item_category2: categoryData.category2,
					item_category3: categoryData.category3,
					item_category4: categoryData.category4,
					item_category5: categoryData.category5,
					item_list_id: categoryData.list_id,
					item_list_name: categoryData.list_name,
					item_variant: variant,
					location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
					price: price,
					quantity: 1
				}
			]
		}
	};
	window.dataLayer.push(function () {
		this.set('ecommerce.items', undefined);
	});
	window.dataLayer.push(data);
}

function addToWishlist() {
	$('body, #mto-modal').on('click', '.wishlistTile', function(e) {
		e.preventDefault();
		e.stopPropagation();
		const icon = $(this).find($('i'));
		const url = $(this).attr('href');
		const index = $($(".wishlistTile")).index($(this));
		var pid = $(this)
			.closest('.product')
			.data('pid');

			if(!pid){
				pid = $(this)
				.closest('.card-body')
				.data('pid');
			}
		let optionId = $(this)
			.closest('.product-detail')
			.find('.product-option')
			.attr('data-option-id');
		let optionVal = $(this)
			.closest('.product-detail')
			.find('.options-select option:selected')
			.attr('data-value-id');
		optionId = optionId || null;
		optionVal = optionVal || null;
		if (!url || !pid) {
			return;
		}

		$.ajax({
			url: url,
			type: 'post',
			dataType: 'json',
			data: {
				pid: pid,
				optionId: optionId,
				optionVal: optionVal,
			},
			success: function(response) {
				displayMessageAndChangeIcon(response, icon, index);
			},
			error: function(err) {
				displayMessageAndChangeIcon(err, icon, index);
			},
		});
	});
}

module.exports = {
	addToWishlist: addToWishlist,
};
