const {isGTMEnabled} = require('../components/helpers');

module.exports = {
	swatch({
		productSelector = '.product-detail',
		productDetails = '.pdp-sidebar',
		productPriceSelector = '[data-gtm-price]',
		productTileNameSelector = '.product-name',
		productTileDescriptionSelector = '.product-description',
		colorAttributesContainer = '.color-attributes-container',
	} = {}) {
		if (!isGTMEnabled()) {
			return;
		}

		$('.color-attribute').on('click', function(e) {
			e.preventDefault();

			const $productDetails = $(this).closest(productDetails);
			const gtmData = $productDetails.find(colorAttributesContainer).data('gtm');

			$('body').bootstrap('gtm', {
				type: 'swatch',
				data: gtmData,
			});
		});
	},
};
