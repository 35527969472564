const toastMessage = require('../js/components/toast-message');
var approachingDiscounts = require('./components/approaching-discounts');

function handlePostCartAdd(response) {
    $('.minicart .minicart-quantity').text(response.quantityTotal);
    // const messageType = response.error ? 'alert-danger' : 'alert-success';

    // if ($('.add-to-cart-from-tile-messages').length === 0) {
    //     $('body').append('<div class="add-to-cart-from-tile-messages"></div>');
    // }

    // $('.add-to-cart-from-tile-messages').append(
    //     `<div class="alert ${messageType} add-to-basket-alert text-center" role="alert">${response.message}</div>`,
    // );

    setTimeout(function () {
        $('.add-to-basket-alert').remove();
    }, 5000);
}

function addRatingDiv(){
    var element = document.getElementsByClassName("trustpilot-widget");
    for(var i=0; i<element.length; i++) {
        window.Trustpilot.loadFromElement(element[i]);
    }
};

function parseHtml(html) {
	const $html = $('<div>').append($.parseHTML(html));

	const body = $html.find('.product-quickview');
	const footer = $html.find('.modal-footer').children();

	return {body: body, footer: footer};
}

jQuery('body').on(
	'click',
	'.qw-buttons .quick-view-button, .add-to-cart-style-store-locator, #plpQuickViewModal .select-store-button',
	function(e) {
    e.preventDefault();
    if ($(this).parents('.modal-dialog').length) {
        $(this).parents('.modal-dialog').spinner().start();
    } else {
        $.spinner().start();
    }

    const $item = $(this).parent();
    if ($item.data('store')) {
        const addToCartUrl = $item.find('#addtocarturl').val();

        const form = {
            pid: $item.attr('data-pid'),
            quantity: 1,
            storeId: $item.find('#storeid').val(),
        }
        var showApproachingPromotionMsg = $(this).closest('.plp-product').find('.product-promotions').data('showapproachingpromotionmsg');
        $.ajax({
            type: "POST",
            url: addToCartUrl,
            data: form,
            success: function (response) {
                $.spinner().stop();
                handlePostCartAdd(response);
                let htmlToAppend = '';
                if (response.cart.approachingDiscounts.length > 0) {
                    for (let i = 0; i < response.cart.approachingDiscounts.length; i++) {
                        var approachingDiscount = response.cart.approachingDiscounts[i];
                        if (approachingDiscount.showApproachingPromotionMsg === true && showApproachingPromotionMsg === true) {
                            htmlToAppend += '<div class="approaching-discounts-inner"><div class="single-approaching-discount text-center">';
                            htmlToAppend += '<span class="single-approaching-discount-message">' + approachingDiscount.discountMsg + '</span>';
                            htmlToAppend += '<div class="group-progress"><p class="progress" data-conditionthreshold="'+ approachingDiscount.conditionThreshold +'" data-merchandisetotal="'+ approachingDiscount.merchandiseTotal +'" data-distancefromconditionthreshold="'+ approachingDiscount.distanceFromConditionThreshold +'"><span class="progress-bar"></span><span class="icon"><span class="fa fa-check"></span></span></p></div>';
                            htmlToAppend += '<span class="single-approaching-discount-disclaimer">' + approachingDiscount.disclaimer + '</span>' || '';
                            htmlToAppend += '</div></div>';
                        }
                    }
                    $('.approaching-discounts').empty().append(htmlToAppend);
                    approachingDiscounts.loadProgress();
                } else {
                    $('.approaching-discounts').empty().append(htmlToAppend);
                }
                $("#addToCartMessage-modal").modal('show');
            },
            error: function (error) {
                $.spinner().stop();
            }
        });
    } else {
        window.storeSelectFlyout.openStoreSelectFlyout();
        document.getElementById('storeAddress').focus();
        document.getElementById("storeAddress").select();
        $("<input type='hidden' />")
     .attr("id", "product-id")
     .attr("value", $item.data('pid'))
     .prependTo("#storeselect-modal");
     $("<button class='d-none add-to-cart'></button>")
     .prependTo("#storeselect-modal");
     if ($item.data('is-quickview')) {
        $("<input type='hidden' />")
        .attr("id", "plpQuickView")
        .attr("value", $item.data('is-quickview'))
        .prependTo("#storeselect-modal");
     }
        $.spinner().stop();
    }
});

jQuery('body').on('click', '#addToCartMessage-modal .modal-button-vertical.btn-group button:last', function(e){
    e.preventDefault();
    $("#addToCartMessage-modal").modal('hide');
});

jQuery('body').on('click', ".details-button", function (e) {
    e.preventDefault();
    $.spinner().start();
    const quickviewurl = $(this).parent().find('#getquickviewurl').val();
    const form = {
        pid: $(this).parent().attr('data-pid'),
        uuid: $(this).parent().data('uuid'),
    }
    $.ajax({
        type: "get",
        url: quickviewurl,
        data: form,
        success: function (response) {
            if ($("#plpQuickViewModal").length !== 0) {
                $("#plpQuickViewModal").remove();
            }
            $('body').append(response.renderedTemplate);
            $("#plpQuickViewModal").modal('show');

            var element = document.getElementsByClassName("trustpilot-widget top-widget");
            for(var i=0; i<element.length; i++) {
                window.Trustpilot.loadFromElement(element[i]);
                break;
            }
            if (response.gtmData) {
                window.dataLayer.push(response.gtmData);
            }
            $.spinner().stop();
        },
        error: function (error) {
            $.spinner().stop();
        }
    });
});

jQuery('body').on('click', '#plpQuickViewModal .add-to-cart-button', function (e) {
    e.preventDefault();
    $.spinner().start();
    $("#plpQuickViewModal").modal('hide');
    const $item = $(this).parent();
    const addToCartUrl = $item.find('#addtocarturl').val();

    const form = {
        pid: $item.data('pid'),
        quantity: $("#plpQuickViewModal").find('.quantity-select').val(),
        storeId: $item.find('#storeid').val(),
    }
    var showApproachingPromotionMsg = $item.data('showapproachingpromotionmsg');
    $.ajax({
        type: "POST",
        url: addToCartUrl,
        data: form,
        success: function (response) {
            $.spinner().stop();
            handlePostCartAdd(response);
            let htmlToAppend = '';
            if (response.cart.approachingDiscounts.length > 0) {
                for (let i = 0; i < response.cart.approachingDiscounts.length; i++) {
                    var approachingDiscount = response.cart.approachingDiscounts[i];
                    if (approachingDiscount.showApproachingPromotionMsg === true && showApproachingPromotionMsg === true) {
                        htmlToAppend += '<div class="approaching-discounts-inner"><div class="single-approaching-discount text-center">';
                        htmlToAppend += '<span class="single-approaching-discount-message">' + approachingDiscount.discountMsg + '</span>';
                        htmlToAppend += '<div class="group-progress"><p class="progress" data-conditionthreshold="'+ approachingDiscount.conditionThreshold +'" data-merchandisetotal="'+ approachingDiscount.merchandiseTotal +'" data-distancefromconditionthreshold="'+ approachingDiscount.distanceFromConditionThreshold +'"><span class="progress-bar"></span><span class="icon"><span class="fa fa-check"></span></span></p></div>';
                        htmlToAppend += '<span class="single-approaching-discount-disclaimer">' + approachingDiscount.disclaimer + '</span>' || '';
                        htmlToAppend += '</div></div>';
                    }
                }
                $('.approaching-discounts').empty().append(htmlToAppend);
                approachingDiscounts.loadProgress();
            } else {
                $('.approaching-discounts').empty().append(htmlToAppend);
            }
            $("#addToCartMessage-modal").modal('show');
        },
        error: function (error) {
            $.spinner().stop();
        }
    });
});

jQuery('body').on('click', "#plpQuickViewModal .select-store-button", function (e) {
    e.preventDefault();
    $("#plpQuickViewModal").modal('hide');
    window.storeSelectFlyout.openStoreSelectFlyout();

    document.getElementById('storeAddress').focus();
    document.getElementById("storeAddress").select();
});

jQuery('body').on('click', "#plpQuickViewModal .color-attribute", function (e) {
    e.preventDefault();
    var params = {};
    $(this).data('url').replace(/[?&]+([^=&]+)=([^&]*)/gi, function (str, key, value) {
        params[key] = value;
    });
    // $.spinner().start();

    const quickviewurl = $("#plpQuickViewModal").find('#quickviewurl').val();
    const form = {
        pid: params.pid,
        uuid: params.uuid,
    }
    // $("#plpQuickViewModal").modal('hide');
    $.ajax({
        type: "get",
        url: quickviewurl,
        data: form,
        success: function (response) {
            // if ($("#plpQuickViewModal").length !== 0) {
            //     $("#plpQuickViewModal").remove();
            // }
            // $('body').append(response.renderedTemplate);
            const parsedHtml = parseHtml(response.renderedTemplate);

			$('#plpQuickViewModal .modal-body').empty();
			$('#plpQuickViewModal .modal-body').html(parsedHtml.body);
			$('#plpQuickViewModal .modal-footer').html(parsedHtml.footer);
            $("#plpQuickViewModal").modal('show');
            addRatingDiv();
            // $.spinner().stop();
        },
        error: function (error) {
            // $("#plpQuickViewModal").modal('show');
            // $.spinner().stop();
        }
    });
});