/* globals dataLayer */

if (typeof dataLayer === 'undefined') {
	window.dataLayer = [];
}

const eventTimeout = 2000; // Please, read: https://www.simoahava.com/gtm-tips/use-eventtimeout-eventcallback/

const dataLayerStorage = {
	storage: {},

	setArrayItem(type, data) {
		if (!this.storage[type]) {
			this.storage[type] = [];
		}

		this.storage[type].push(data);
	},

	getItem(type) {
		return this.storage[type];
	},

	removeItem(type) {
		delete this.storage[type];
	},
};

function eventCallbackDecorator(obj) {
	const { eventCallback } = obj;
	let isFired;

	obj.eventTimeout = eventTimeout;
	obj.eventCallback = function () {
		isFired = true;
		eventCallback();
	};

	if (obj.eventTimeout) {
		setTimeout(function () {
			if (isFired) return;
			eventCallback();
		}, obj.eventTimeout);
	}

	return obj;
}

const dataLayerObjects = {
	impressions: function (products, { list }) {
		return ({
			event: 'productImpression',
			ecommerce: {
				impressions: products.map((product, i) => ({
					id: product.sku,
					name: `${product.name}`,
					currency: product.currency,
					price: product.price,
					list,
					position: ++i,
					attributes: product.attributes,
				})),
			},
		})
	},
	view_item_list: function (products) {
		window.dataLayer.push(function () {
			this.set('ecommerce', undefined);
		});
		var data = {
			event: 'view_item_list',
			ecommerce: {
				items: products.itemsData
			}
		}
		return data;
	},
	detailView: products => ({
		ecommerce: {
			detail: {
				products: products.map(product => ({
					id: product.sku,
					name: product.name,
					price: product.price,
					currency: product.currency,
					attributes: product.attributes,
				})),
			},
		},
	}),
	productClick: function(product) {
		if (product && product.sku) {
			return eventCallbackDecorator({
				event: 'productClick',
				ecommerce: {
					click: {
						products: [
							{
								id: product.sku,
								name: product.name,
								price: product.price,
							},
						],
					},
				},
				eventCallback() {
					document.location = product.url;
				},
			});
		}
	},
	select_item: function (product) {
		window.dataLayer.push(function () {
			this.set('ecommerce', undefined);
		});
		if (product && product.sku) {
			return eventCallbackDecorator({
				event: 'select_item',
				ecommerce: {
					items: [
						{
							item_id: product.sku,
							item_name: product.name,
							affiliation: 'Amart Online',
							coupon: product.promotionList,
							currency: product.currency,
							discount: product.discount,
							index: product.index,
							item_brand: product.brand,
							item_category: product.categoryData ? product.categoryData.category1 : '',
							item_category2: product.categoryData ? product.categoryData.category2 : '',
							item_category3: product.categoryData ? product.categoryData.category3 : '',
							item_category4: product.categoryData ? product.categoryData.category4 : '',
							item_category5: product.categoryData ? product.categoryData.category5 : '',
							item_list_id: product.categoryData ? product.categoryData.list_id : '',
							item_list_name: product.categoryData ? product.categoryData.list_name : '',
							item_variant: product.variant,
							location_id: '',
							price: product.price,
							quantity: 1
						}
					]
				},
				eventCallback() {
					return true;
				},
			})
		}
		return true;
	},
	addToCart: function(product) {
		if (product && product.sku) {
			return eventCallbackDecorator({
				event: 'addToCart',
				ecommerce: {
					add: {
						products: [
							{
								id: product.sku,
								name: product.name,
								price: product.price,
								currency: product.currency,
								quantity: product.quantity,
								attributes: product.attributes,
							},
						],
					},
				},
				eventCallback() {
					return true;
				},
			})
		}
	},
	add_to_cart: function (product) {
		window.dataLayer.push(function () {
			this.set('ecommerce', undefined);
		});
		if (product && product.sku) {
			return eventCallbackDecorator({
				event: 'add_to_cart',
				ecommerce: {
					items: [
						{
							item_id: product.sku,
							item_name: product.name,
							affiliation: "Amart Online",
							coupon: product.promotionList,
							currency: product.currency,
							discount: product.discount,
							index: product.index,
							item_brand: product.brand,
							item_category: product.categoryData ? product.categoryData.category1 : '',
							item_category2: product.categoryData ? product.categoryData.category2 : '',
							item_category3: product.categoryData ? product.categoryData.category3 : '',
							item_category4: product.categoryData ? product.categoryData.category4 : '',
							item_category5: product.categoryData ? product.categoryData.category5 : '',
							item_list_id: product.categoryData ? product.categoryData.list_id : '',
							item_list_name: product.categoryData ? product.categoryData.list_name : '',
							item_variant: product.variant,
							location_id: "",
							price: product.price,
							quantity: product.quantity,
							attributes: product.attributes,
						}
					]
				},
				eventCallback() {
					return true;
				},
			})
		}
		return true;
	},
	swatch: function(product) {
		if (product && product.sku) {
			return eventCallbackDecorator({
				event: 'swatch',
				ecommerce: {
					details: {
						products: [
							{
								id: product.sku,
								name: product.name,
								price: product.price,
							},
						],
					},
				},
				eventCallback() {
					return true;
				},
			})
		}
	},
	removeFromCart: function(product) {
		if (product && product.sku) {
			return eventCallbackDecorator({
				event: 'removeFromCart',
				ecommerce: {
					remove: {
						products: [
							{
								id: product.sku,
								name: product.name,
								price: product.price,
								quantity: product.quantity,
							},
						],
					},
				},
				eventCallback() {
					return true;
				},
			})
		}
	},
	remove_from_cart: function (product) {
		window.dataLayer.push(function () {
			this.set('ecommerce', undefined);
		});
		if (product && product.sku) {
			return eventCallbackDecorator({
				event: 'remove_from_cart',
				ecommerce: {
					items: [
						{
							item_id: product.sku,
							item_name: product.name,
							affiliation: "Amart Online",
							coupon: product.promotionList,
							currency: product.currency,
							discount: product.discount,
							index: product.index,
							item_brand: product.brand,
							item_category: product.categoryData ? product.categoryData.category1 : '',
							item_category2: product.categoryData ? product.categoryData.category2 : '',
							item_category3: product.categoryData ? product.categoryData.category3 : '',
							item_category4: product.categoryData ? product.categoryData.category4 : '',
							item_category5: product.categoryData ? product.categoryData.category5 : '',
							item_list_id: product.categoryData ? product.categoryData.list_id : '',
							item_list_name: product.categoryData ? product.categoryData.list_name : '',
							item_variant: product.variant,
							location_id: "",
							price: product.price,
							quantity: product.quantity
						}
					]
				},
				eventCallback() {
					return true;
				},
			})
		}
		return true;
	},
	cartAction: (products, { type }) => ({
		event: type === 'remove' ? 'removeFromCart' : 'addToCart',
		ecommerce: {
			[type]: {
				products: products.map(({ sku, name, price, quantity, currency }) => {
					const product = {
						id: sku,
						name,
					};

					if (price) {
						product.price = price;
					}

					if (currency) {
						product.currency = currency;
					}

					if (quantity) {
						product.quantity = quantity;
					}

					return product;
				}),
			},
		},
	}),
	checkout: function(
		products,
		{
			url,
			actionField = {
				step: 2,
				option: 'ContactDetails',
				action: 'checkout',
			},
		},
	) {
		if (product && product.sku) {
			return eventCallbackDecorator({
				event: 'checkout',
				ecommerce: {
					actionField: actionField,
					products: products.map(product => ({
						id: product.sku,
						name: product.name,
						currency: product.currency,
						price: product.price,
						quantity: product.quantity,
						attributes: product.attributes,
					})),
				},
				eventCallback() {
					if (url) {
						location.href = url;
					}
				},
			})
		}
	},
	checkoutOption: options => ({
		event: 'checkoutOption',
		ecommerce: {
			checkout_option: { actionField: options },
		},
	}),
	addToWishlist: product =>
		eventCallbackDecorator({
			event: 'addToWishlist',
			ecommerce: {
				add: {
					products: [
						{
							id: product.id,
							name: product.name,
							image: product.image,
						},
					],
				},
			},
			eventCallback() {
				return true;
			},
		}),
	removeFromWishlist: product =>
		eventCallbackDecorator({
			event: 'removeFromWishlist',
			ecommerce: {
				remove: {
					products: [
						{
							id: product.id,
							name: product.name,
							image: product.image,
						},
					],
				},
			},
			eventCallback() {
				return true;
			},
		}),
	storeChanged: store =>
		eventCallbackDecorator({
			event: 'storeChanged',
			ecommerce: {
				update: {
					store: [
						{
							name: store.name,
							region: store.region,
						},
					],
				},
			},
			eventCallback() {
				return true;
			},
		}),
	add_payment_info: function(options) {
		window.dataLayer.push(function() {
			this.set('ecommerce', undefined);
		});
		return eventCallbackDecorator({
			event: 'add_payment_info',
			ecommerce: {
				currency: options.ecommerce.currency,
				value: options.ecommerce.value,
				coupon: options.ecommerce.coupon,
				payment_type: options.ecommerce.paymentMethod,
				items: options.ecommerce.items,
			},
			eventCallback() {
				return true;
			},
		})
	},
};

/** *******************
Public Methods
**********************/

export default function ({ type, data, options }) {
	const processedObject = dataLayerObjects[type](data, options);
	dataLayer.push(processedObject);
}

export function saveData({ type, data }) {
	dataLayerStorage.setArrayItem(type, data);
}

export function clearData({ type }) {
	dataLayerStorage.removeItem(type);
}

export function applyData({ type, options, notClearStorage }) {
	const data = dataLayerStorage.getItem(type);
	if (!data) return;

	const processedObject = dataLayerObjects[type](data, options);

	dataLayer.push(processedObject);

	if (!notClearStorage) {
		dataLayerStorage.removeItem(type);
	}
}
