const {isGTMEnabled} = require('../components/helpers');

module.exports = {
	addToCart({
		productSelector = '.product-detail',
		productDetails = '.pdp-sidebar',
		productPriceSelector = '[data-gtm-price]',
		productTileNameSelector = '.product-name',
		productTileDescriptionSelector = '.product-description',
		productQuantitySelector = '.quantity-select',
		productCurrencySelector = 'gtm-currency',
		productAttributes = 'gtm-attributes',
	} = {}) {
		if (!isGTMEnabled()) {
			return;
		}

		$('.pdp-sidebar')
			.find('.add-to-cart')
			.on('click', function(e) {
				e.preventDefault();
				const gtmData = $(this).data('gtm');
				const $product = $(this).closest(productSelector);
				const $productDetails = $(this).closest(productDetails);
				const sku = `${$product.data('pid')}`;
				const nameOne = $productDetails
					.find(productTileNameSelector)
					.text()
					.trim();
				const nameTwo = $productDetails
					.find(productTileDescriptionSelector)
					.text()
					.trim();
				const name = `${nameOne} - ${nameTwo}`;
				const price = $productDetails
					.find(productPriceSelector)
					.find('.value')
					.attr('content');
				const quantity = $productDetails
					.find(productQuantitySelector)
					.find('option:selected')
					.text()
					.trim();
				const currency = $productDetails.data(productCurrencySelector);
				const attributes = $productDetails.data(productAttributes);

				const brand = $product.find(".product-ctas").data('product-brand') ? $product.find(".product-ctas").data('product-brand') : "";
				const variant = $product.find(".product-ctas").data('product-variant') ? $product.find(".product-ctas").data('product-variant') : "";
				const category = $product.find(".product-ctas").data('product-category');
				const index = 0;
				const priceObj = $product.find(".product-ctas").data('product-priceobj');
                const discount = priceObj.list ? priceObj.list.value - priceObj.sales.value : 0;
                const promotions = $product.find(".product-ctas").data('promotions');
                var promotionList = [];
                if (promotions) {
                    for (let i = 0; i < promotions.length; i++) {
                        promotionList.push({
                            id: promotions[i].id,
                            name: promotions[i].name
                        })
                    }
                }

				const categoryData = {
					category1: category && category.length > 0 && category[0] ? category[0].name : "",
					category2: category && category.length > 0 && category[1] ? category[1].name : "",
					category3: category && category.length > 0 && category[2] ? category[2].name : "",
					category4: category && category.length > 0 && category[3] ? category[3].name : "",
					category5: category && category.length > 0 && category[4] ? category[4].name : "",
					list_id: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].id : '',
					list_name: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].name : '',
				}

				$('body').bootstrap('gtm', {
					type: 'addToCart',
					data: gtmData,
				});
				$('body').bootstrap('gtm', {
					type: 'add_to_cart',
					data: gtmData,
				});

				// Add pinterest tag
				var pScript = document.createElement("script");
				pScript.innerHTML = "pintrk('track', 'AddToCart', {value: " + price + ",order_quantity: " + quantity + ",currency: 'AUD'});";
				document.head.appendChild(pScript);
				$("<noscript><img height='1' width='1' style='display:none;' alt='' src='https://ct.pinterest.com/v3/?tid=2613557919181&event=AddToCart&ed[value]="+ price +"&ed[order_quantity]="+ quantity +"&noscript=1' /></noscript>").appendTo(document.head);
			});

			$('body').on('click', '#addToCartMessage-modal .add-to-cart-tile', function(e){
				const gtmData = $(this).data('gtm');
				const $product = $(this);
				const $productDetail = $(this).closest('.card.product-tile-card');

				const name = $productDetail.find('.card-title.product-tile-name').text().trim() +' - '+ $productDetail.find('.card-text.product-tile-subtitle').text().trim();
				const sku = $product.data('pid');
				const price = $product.data('product-price');
				const currency = $product.data('product-currency');
				const brand = $product.data('product-brand');
				const variant = $product.data('product-variant');
				const quantity = 1;
				const category = $product.data('product-category');
				const index = $($("#addToCartMessage-modal-recommendations .card.product-tile-card")).index($(this).closest('.card.product-tile-card'));;
				const priceObj = $product.data('product-priceobj');
                const discount = priceObj.list ? priceObj.list.value - priceObj.sales.value : 0;
                const promotions = $product.data('promotions');
                var promotionList = [];
                if (promotions) {
                    for (let i = 0; i < promotions.length; i++) {
                        promotionList.push({
                            id: promotions[i].id,
                            name: promotions[i].name
                        })
                    }
                }
				const categoryData = {
					category1: category && category.length > 0 && category[0] ? category[0].name : "",
					category2: category && category.length > 0 && category[1] ? category[1].name : "",
					category3: category && category.length > 0 && category[2] ? category[2].name : "",
					category4: category && category.length > 0 && category[3] ? category[3].name : "",
					category5: category && category.length > 0 && category[4] ? category[4].name : "",
					list_id: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].id : "",
					list_name: category && category.length > 0 && category[category.length - 1] ? category[category.length - 1].name : "",
				}

				const instock = $product.data('product-instock');
				const attributes = instock ? 'In Stock' : 'Out Of Stock';

				$('body').bootstrap('gtm', {
					type: 'add_to_cart',
					data: gtmData,
				});

				$('body').bootstrap('gtm', {
					type: 'addToCart',
					data: gtmData,
				});
		});
	},
};
