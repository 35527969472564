const processInclude = require('sfraScripts/util');
$ = require('jquery');
const modules = require('./utils/bootstrapper').modules;
const gtm = require('./components/gtm');
const {isGTMEnabled} = require('./components/helpers');
var posOld = 0;

$(document).ready(function() {
	processInclude(require('./components/menu-ext'));
	processInclude(require('./components/menu-mobile'));
	processInclude(require('./components/menu-storeselect'));
	processInclude(require('./components/nearby-store'));
	processInclude(require('./components/collapsible'));
	processInclude(require('./store/preferred-store'));
	processInclude(require('./checkout/guest-checkout'));
	processInclude(require('./components/header-search'));
	processInclude(require('sfraScripts/productTile'));
	processInclude(require('./components/tab-carousel'));
	processInclude(require('./components/product-tabs'));
	processInclude(require('./components/wishlist-heart'));
	processInclude(require('./components/subscription'));
	processInclude(require('./components/product-image-carousel'));
	processInclude(require('./product/tile'));
	processInclude(require('./product/addToCartPdp'));
	processInclude(require('./product/swatchPdp'));
	processInclude(require('./cart/removeFromCart'));
	processInclude(require('./wishlist/addToCartWishlist'));
	processInclude(require('./wishlist/addToCartModal'));
	processInclude(require('./product/quickView'));
	processInclude(require('./components/cart-modal'));
	processInclude(require('./addtoCartButton'));
	processInclude(require('./product/addToCartPlp'));
	processInclude(require('./components/selectPromotionEvent'));
	processInclude(require('sfraScripts/components/toolTip'));
	processInclude(require('./applepay'));
	processInclude(require('./components/approaching-discounts'));
});

require('./components/spinner');

if (isGTMEnabled()) {
	Object.assign(modules, {
		gtm: gtm.default,
		gtmSaveData: gtm.saveData,
		gtmApplyData: gtm.applyData,
		gtmClearData: gtm.clearData,
	});
}

if (!window.zESettings) {
	window.zESettings = {};
}
if (window.zESettings) {
	if (!window.zESettings.webWidget) {
		window.zESettings.webWidget = {};
	}
	window.zESettings.webWidget.offset = {
		vertical: '0px',
		horizontal: '-5px',
		mobile: {
			horizontal: '-5px',
			vertical: '60px',
		}
	};
}
window.storeSelectFlyout = (function () {
	var storeSelect = {
		openStoreSelectFlyout: function () {
			$('body').addClass('modal-open');
			$('body').addClass('disable-overflow');
			$('body > .modal-backdrop').remove();
			$('body').append('<div class="modal-backdrop fade show"></div>');
			$('.storeselect-flyout').animate({
				'right': '0'
			}, 500);

			setTimeout(function(){
				$('#storeSelectFormModal').trigger('submit');
			}, 100);
		}
	};
	return storeSelect;
}());

$(document).on('hidden.bs.modal', function (e) {
	if (e.target.id === 'requestPasswordResetModal') {
		if ($("#submitEmailButton").data('dismiss') == 'modal') {
			location.reload();
			$.spinner().start();
		}

		var checkoutLoginPage = $('#checkoutLoginPage').val();
		if (checkoutLoginPage && checkoutLoginPage === 'true') {
			var html = $('#requestPasswordResetModalBackup').html();
			$('#requestPasswordResetModal').empty().html(html);
		}
    } 
});

$('body').on('click', '.nook-top-banner .fa-youtube-play', function(e) {
	e.preventDefault();
	var url = $('.nook-top-banner iframe').attr('src'); 
	url += '&autoplay=1';
	$('.nook-top-banner iframe').attr('src', url);
	$('.nook-top-banner .top-banner-wrapper').addClass('d-none');
	$('.nook-top-banner .img-wrapper').addClass('open');
	$(this).addClass('d-none');
});

$('body').on('click', '.goto-pdp-reviews', function(e) {
	var reviewUrl = $(this).data('tpurl');
	window.location.href = reviewUrl;
});

$( ".goto-pdp-reviews" ).bind('mouseover', function(e) {
	$(this).find('iframe:nth-child(2)').css('display','block');
}).bind('mouseleave', function() {
	$(this).find('iframe:nth-child(2)').css('display','none');
});

$(window).on('load', function() {
	if (window.location.href.indexOf("#tpReviews") > -1) {
		$('#pdpAccordion-headingReviews').trigger('click');
		var prev = $('#tpreviews').prev();
		var bannerCarouselHeight = $('.maincontent > .trust-banner-carousel-container.visible').length > 0 ? $('.maincontent > .trust-banner-carousel-container.visible').innerHeight() : 0;
		$('html, body').animate({
			scrollTop: prev.offset().top + prev.innerHeight() - ($('header').innerHeight() + bannerCarouselHeight)
		});
	}
});

$(window).on('scroll', function() {
	if($('.store-search-custom').find('#store-address').is(':focus')) {
		let pos = $('.store-search-custom').offset().top + $('.store-search-custom').innerHeight();
		if (posOld !== pos) {
			$('.pac-container').css('top', Math.floor(pos));
			posOld = pos;
		}
	}
});

require('./utils/bootstrapper').apply();
