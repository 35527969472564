// Image carousel plugin for PDP, Product Set, Bonus products modal on cart
require('bootstrap/js/src/modal.js');

const PhotoSwipe = require('photoswipe/dist/photoswipe');
const PhotoSwipeUIDefault = require('photoswipe/dist/photoswipe-ui-default');

function openPhoto(items, index) {
	// Find currently selected photo to open
	const pswpElement = document.querySelectorAll('.pswp')[0];
	const options = {
		index: index || 0, // start at first slide
		bgOpacity: 0.7,
		showAnimationDuration: 200,
		hideAnimationDuration: 200,
		tapToClose: true,
		maxSpreadZoom: 7,
		spacing: 0.12,
	};
	const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUIDefault, items, options);
	gallery.init();
}

function initImageCarousel() {
	$('.product-image-primary').slick({
		// lazyLoad: 'ondemand',
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
		asNavFor: '.product-image-nav',
	});

	const $productImageNav = $('.product-image-nav');
	const productImageQ = $productImageNav.find('.image-nav').length;

	$productImageNav.slick({
		// lazyLoad: 'ondemand',
		slidesToShow: 6,
		slidesToScroll: 1,
		asNavFor: '.product-image-primary',
		dots: false,
		centerMode: false,
		focusOnSelect: true,
		responsive: [
			{
				breakpoint: 769,
				settings: {
					slidesToShow: 3,
				},
			},
		],
	});

	let slidesToShow = $(window).width() > 768 ? 6 : 3;

	if (slidesToShow >= productImageQ) {
		$productImageNav.find('.slick-track').addClass('no-move');
	}

	$(window).on('resize', function() {
		slidesToShow = $(window).width() > 768 ? 6 : 3;

		if (slidesToShow >= productImageQ) {
			$productImageNav.find('.slick-track').addClass('no-move');
		} else {
			$productImageNav.find('.slick-track.no-move').removeClass('no-move');
		}
	});
}

module.exports = function() {
	// Initialise photo data
	const items = [];
	$('img.pswp-image').each(function() {
		items.push({
			src: $(this).attr('data-src'),
			w: 1610,
			h: 1110,
		});

		// Set listener for photo zoom
		$(this).click(function() {
			openPhoto(items, $(this).data('index'));
		});
	});

	// Ititialise slick slideshow
	initImageCarousel();
};
